@use "sass:color";
@use "abstracts/variables";

.accordion {
  --bs-accordion-color: #{variables.$text-color};
  --bs-accordion-bg: #{variables.$secondary-bg-color};
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #{variables.$section-border-color};
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: #{variables.$base-border-radius};
  --bs-accordion-inner-border-radius: calc(#{variables.$base-border-radius} - 1px);
  --bs-accordion-btn-padding-x: 20px;
  --bs-accordion-btn-padding-y: 14px;
  --bs-accordion-btn-color: #{variables.$text-color};
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: #{variables.$control-focus-box-shadow};
  --bs-accordion-btn-hover-color: #{variables.$link-color};
  --bs-accordion-btn-hover-bg: #{color.adjust(variables.$secondary-bg-color, $lightness: -2%)};
  --bs-accordion-body-padding-x: 20px;
  --bs-accordion-body-padding-y: 14px;
  --bs-accordion-body-bg: #{variables.$default-bg-color};
  --bs-accordion-active-color: #{variables.$text-color};
  --bs-accordion-active-bg: var(--bs-accordion-bg);
}

[ngbAccordion] {
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: variables.$default-font-size;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .accordion-button:not(.collapsed)::after {
    transform: var(--bs-accordion-btn-icon-transform);
  }
  .accordion-button::after {
    flex-shrink: 0;
    margin-left: auto;
    content: "\f107";
    font-family: FontAwesome;
    font-size: 20px;
    transition: var(--bs-accordion-btn-icon-transition);
  }
  .accordion-button:disabled {
    color: #989898;
    cursor: not-allowed;

    &::after {
      color: #989898;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none;
    }
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:hover:not([disabled]) {
    color: var(--bs-accordion-btn-hover-color);
    background-color: var(--bs-accordion-btn-hover-bg);
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
  }
  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }

  .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
    background-color: var(--bs-accordion-body-bg);
  }

  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0;
  }
}
